import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`We are heading into April, and we see some changes in the markets. Stocks and bonds alike have recovered – but will it hold?`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lgB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABPyFf/9oADAMBAAIAAwAAABDQz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAMAAgMAAAAAAAAAAAAAAAABESFREHGR/9oACAEBAAE/EJFhLYncxjreuETv0//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mv monthly",
            "title": "mv monthly",
            "src": "/static/0d44f114a9077954c2528187d18be8da/e5166/mv-monthly.jpg",
            "srcSet": ["/static/0d44f114a9077954c2528187d18be8da/f93b5/mv-monthly.jpg 300w", "/static/0d44f114a9077954c2528187d18be8da/b4294/mv-monthly.jpg 600w", "/static/0d44f114a9077954c2528187d18be8da/e5166/mv-monthly.jpg 1200w", "/static/0d44f114a9077954c2528187d18be8da/eea4a/mv-monthly.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`The Crystal Ball`}</h2>
    <p>{`Let’s begin with looking at `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{`, which entered bullish territory for the month of April. While the economic momentum continues to be negative, the stock market momentum has turned sharply positive, just in the past few days of March. In combination with the positive stock vs bond performance, this is a 2-to-1 vote for bullish momentum. `}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gFH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFxAAAwEAAAAAAAAAAAAAAAAAEBEggf/aAAgBAQABPyFDY//aAAwDAQACAAMAAAAQcA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQADAAAAAAAAAAAAAAABEQAQITH/2gAIAQEAAT8QAVOm3FfWZl5Ab//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "stock market",
            "title": "stock market",
            "src": "/static/b6490e3c0b65dbda48e24b0808a1e7c8/e5166/stock-market.jpg",
            "srcSet": ["/static/b6490e3c0b65dbda48e24b0808a1e7c8/f93b5/stock-market.jpg 300w", "/static/b6490e3c0b65dbda48e24b0808a1e7c8/b4294/stock-market.jpg 600w", "/static/b6490e3c0b65dbda48e24b0808a1e7c8/e5166/stock-market.jpg 1200w", "/static/b6490e3c0b65dbda48e24b0808a1e7c8/eea4a/stock-market.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`stock market’s 50-day moving average`}</a>{` trades above the 200-day average, and has been for two months now. Also, 30-day and 1-year implied volatility are within normal levels, indicating the absence of any imminent market fear.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gFH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAAIDAAAAAAAAAAAAAAAAAQARIEGB/9oACAEBAAE/IQDcudw//9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAQUAAAAAAAAAAAAAAAEAERAhMUFxof/aAAgBAQABPxCxTc22zt5HXlKMBP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "bond market",
            "title": "bond market",
            "src": "/static/455b1f4275cfac5df63c07e9d04b8bfe/e5166/bond-market.jpg",
            "srcSet": ["/static/455b1f4275cfac5df63c07e9d04b8bfe/f93b5/bond-market.jpg 300w", "/static/455b1f4275cfac5df63c07e9d04b8bfe/b4294/bond-market.jpg 600w", "/static/455b1f4275cfac5df63c07e9d04b8bfe/e5166/bond-market.jpg 1200w", "/static/455b1f4275cfac5df63c07e9d04b8bfe/eea4a/bond-market.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`bond market shows a sudden decline in yields`}</a>{` in mid-March, most notably for 2-year maturities. What stands out here is that the longer-term yields remained low, even though we can expect the Fed to further raise rates. However, as shown by the 13-week yield only marginally above Federal Funds Rate, the market expects these rate hikes at a slower pace.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lEUf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABUQAQEAAAAAAAAAAAAAAAAAACAx/9oACAEBAAE/IYv/2gAMAwEAAgADAAAAEHDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMRAh/9oACAEBAAE/EEoczStrB764TkR//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "eco big4",
            "title": "eco big4",
            "src": "/static/c82a75ef1e87f7a4ca1dcedeff973337/e5166/eco-big4.jpg",
            "srcSet": ["/static/c82a75ef1e87f7a4ca1dcedeff973337/f93b5/eco-big4.jpg 300w", "/static/c82a75ef1e87f7a4ca1dcedeff973337/b4294/eco-big4.jpg 600w", "/static/c82a75ef1e87f7a4ca1dcedeff973337/e5166/eco-big4.jpg 1200w", "/static/c82a75ef1e87f7a4ca1dcedeff973337/eea4a/eco-big4.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Looking at the economy, we can see that `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`industrial production continues to be off-peak`}</a>{` after dropping in Q4 2022. We can also see that real sales are stagnating. And while this measure does this from time to time, it sticks out that this measure has been flat for three months now. Combined, we can finally see the rate hikes cooling the economy - which is unavoidable to fight inflation.`}</p>
    <p>{`Only time will tell if this slowdown improves the outcome for investors. However, our take is that any reduction of doubt and uncertainty about the future is an improvement because technical strategies tend to suffer the most in directionless markets.`}</p>
    <p><a parentName="p" {...{
        "href": "portfolios/tt-easy-peasy/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdePUVMS0P8A/8QAGBABAAMBAAAAAAAAAAAAAAAAAgABBBD/2gAIAQEAAQUCVuIaVM4sDv8A/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABcRAAMBAAAAAAAAAAAAAAAAAAABEQL/2gAIAQIBAT8BWoN0/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARAiCBM//aAAgBAQAGPwKsDHs9DkRbb0//xAAbEAACAgMBAAAAAAAAAAAAAAAAARFRECExYf/aAAgBAQABPyHfkLY7iV6gR3oPMH//2gAMAwEAAgADAAAAEIcf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAMf/aAAgBAwEBPxBrrghN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxBOAkWt/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUGxEFHB/9oACAEBAAE/EMxRy+g+wBPXk6Fw08aqNYZsrxTkn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "easy peasy",
            "title": "easy peasy",
            "src": "/static/aa668555e7ad39ca5bb5d4ba1882342e/e5166/easy-peasy.jpg",
            "srcSet": ["/static/aa668555e7ad39ca5bb5d4ba1882342e/f93b5/easy-peasy.jpg 300w", "/static/aa668555e7ad39ca5bb5d4ba1882342e/b4294/easy-peasy.jpg 600w", "/static/aa668555e7ad39ca5bb5d4ba1882342e/e5166/easy-peasy.jpg 1200w", "/static/aa668555e7ad39ca5bb5d4ba1882342e/eea4a/easy-peasy.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`The Simplest of Portfolios`}</h2>
    <p>{`TuringTrader has a strong focus on portfolios that trade daily and require accounts of $50,000 or more. Further, these portfolios trade a broad menu of assets, which may be difficult to map to some restrictive retirement accounts. Specifically, many `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/401(k)"
      }}>{`401(k) plans`}</a>{`, or the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Thrift_Savings_Plan"
      }}>{`Thrift Savings Plan`}</a>{` have very limited options.`}</p>
    <p>{`Our new `}<a parentName="p" {...{
        "href": "/portfolios/tt-easy-peasy/"
      }}>{`Easy-Peasy portfolio`}</a>{` fills that gap. It combines our `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane signal`}</a>{` with `}<a parentName="p" {...{
        "href": "/portfolios/heine-bond-model/"
      }}>{`Heine’s Bond Trading model`}</a>{`, to trade between three U.S.-centric ETFs: Large cap stocks, intermediate-term Treasury Bonds, and the money-market. Nearly all retirement plans should include either the ETFs we have chosen, or funds that can act as drop-in replacements.  `}</p>
    <p>{`With these properties, we believe that `}<a parentName="p" {...{
        "href": "/portfolios/tt-easy-peasy/"
      }}>{`Easy-Peasy`}</a>{` is a useful addition to our lineup, which will undoubtedly find its way onto many dashboards.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      